<script setup lang="ts">
import { Modal } from 'flowbite'
import type { ModalOptions, ModalInterface } from 'flowbite'

defineProps<{
  name: String,
  brandName: String,
}>()

const modalEl = ref(null)
const modal = ref<null | ModalInterface>(null)

const options: ModalOptions = {
  backdrop: 'dynamic',
  backdropClasses: 'bg-gray-500 bg-opacity-50 fixed inset-0 z-40',
  closable: true,
  onHide () {
    // Show notes after modal
    window.dispatchEvent(new CustomEvent('plann-show-notes', {}))
  }
}
// initialize components based on data attribute selectors
onMounted(() => {
  if (window && window.localStorage) {
    const value = window.localStorage.getItem('welcome-modal')
    if (!value) {
      modal.value = new Modal(modalEl.value, options)
      modal.value.show()
      window.localStorage.setItem('welcome-modal', 'true')
    }
  }
})

function hide () {
  modal.value && modal.value.hide()
}
</script>

<template>
  <div id="modalEl" ref="modalEl" aria-hidden="true" class="fixed inset-x-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
    <div class="relative max-h-full w-full max-w-screen-sm lg:max-w-screen-lg">
      <!-- Modal content -->
      <div class="relative flex flex-wrap shadow lg:flex-nowrap">
        <div class="welcome-bg-image relative flex w-full flex-col justify-between overflow-hidden rounded-l-lg bg-plann-blue-400 p-8">
          <PlannLogo class="mb-4 size-[50px] rounded-xl" />
          <div class="text-white">
            <p class="z-10 mb-4 text-3xl font-semibold">
              A Fresh New Way<br>
              to Do Social Media.
            </p>
            <p class="mb-3 font-semibold">
              Plann has helped over 3M global brands:
            </p>
            <ul class="z-50 ml-4 list-inside list-disc space-y-1 dark:text-gray-400">
              <li>
                Come up with never-ending post ideas.
              </li>
              <li>
                Work collaboratively as a team, and with clients.
              </li>
              <li>
                Manage social media + link-in-bio pages together.
              </li>
              <li>
                Visually plan, design and approve social media, all-in-one place!
              </li>
            </ul>
          </div>
        </div>
        <div class="flex flex-col justify-between rounded-r-lg bg-white p-10 shadow">
          <div>
            <p class="mb-4 text-4xl font-semibold">
              Hello, hello!<br> Welcome to Plann ✨
            </p>
            <p class="mb-2">
              <span class="capitalize">{{ name }}</span> has invited you to view {{ brandName }}'s content.
            </p>
            <p class="mb-10">
              You’ll be able to view what’s coming up on the social schedule for {{ brandName }}.
            </p>
          </div>
          <div>
            <div class="mb-8 flex rounded-xl bg-plann-blue-200 p-4">
              <div class="mr-2">
                <Icon size="1.3em" name="LightbulbIcon" />
              </div>
              <div>
                If you would like to comment, compliment, or edit, please ask {{ name }} to add you as an Editor within their own Plann workspace.
              </div>
            </div>
            <button class="w-full rounded-xl bg-plann-blue-500 p-4 font-semibold uppercase text-white" @click="() => hide()">
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.welcome-bg-image {
    background: transparent url("@/assets/img/bg-grid.jpg") top left no-repeat;
    background-size: 100%;
}
</style>
