<template>
  <div class="text-plann-blue-dark antialiased">
    <div class="flex flex-col">
      <NavbarMain
        :brand="brand"
        :active-account="account"
        :accounts="accounts"
        :user="user"
        :note="note"
        :date="updatedAt"
        :expiry="expiry"
        @click-account="(accValue) => changeAccount(accValue)"
      />
      <div class="min-h-screen bg-gray-100">
        <slot />
      </div>
      <ModalPreviewItemModal />
      <ModalWelcomeModal v-if="user && brand" :name="user?.first_name" :brand-name="brand.name" />
      <CookieBanner />
    </div>
  </div>
</template>

<script setup lang="ts">
// https://github.com/aFarkas/lazysizes
import 'lazysizes'
// Detect when image changes https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/attrchange
import 'lazysizes/plugins/attrchange/ls.attrchange'

import { CONTENT_HEADERS } from '~/constants/header.constants'
import { MEDIA_TYPES } from '~/constants/media.constants'
import { platforms } from '~/constants/platforms.constants'
const { $dayjs, $trackMixpanel } = useNuxtApp()

const config = useRuntimeConfig()
const route = useRoute()
// Add exteneral mixpanel
useHead({
  script: [{
    innerHTML: `
      (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
      for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?nMIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match()?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
    `
  }]
})

if (!route.params.id) {
  throw showError({ statusCode: 404, statusMessage: 'This link looks like it has expired, or not quite right.' })
}

// https://nuxt.com/docs/api/composables/use-fetch
const { data } = useLazyFetch(`${config.public.apiBase}/share_links/${route.params.id}`, {
  onResponseError (/* request, response, options */) {
    // https://nuxt.com/docs/api/utils/show-error#showerror
    showError({ statusCode: 404, statusMessage: 'This link looks like it has expired, or not quite right.' })
  }
})

const brand = useBrand()
const user = useUser()
const accounts = useAccountList()

const account = useAccount()
const hideDrafts = useHideDrafts()
const contentHeaders = useContentHeaders()
const activeContentHeader = useActiveContentHeader()
const activePostType = useActivePostType()

const note = ref('')
const expiry = ref('')
const updatedAt = ref('')

// @ts-ignore
watch(data, (response: LinkResponse) => {
  if (response.data.expires_at && $dayjs.utc(response.data.expires_at).isBefore($dayjs())) {
    showError({ statusCode: 404, statusMessage: 'This link has expired.' })
    return
  }
  const { accountId } = route.params

  // Order accounts in correct sequence
  const accountsResponse = response.data.accounts.sort((a, b) => {
    if (Object.values(platforms).indexOf(a.platform) < Object.values(platforms).indexOf(b.platform)) {
      return -1
    }
    return 1
  })

  const accountIndex = accountsResponse.findIndex(acc => acc.id === Number(accountId))
  if (accountId && accountIndex === -1) {
    showError({ statusCode: 404, statusMessage: 'Account Not Found' })
    return
  }
  brand.value = response.data.brand
  user.value = response.data.user

  accounts.value = accountsResponse
  note.value = response.data.note
  hideDrafts.value = response.data.is_hide_drafts && true
  expiry.value = response.data.expires_at
  updatedAt.value = response.data.updated_at

  if (response.data.is_hide_drafts === true) {
    contentHeaders.value = contentHeaders.value.filter(header => header !== CONTENT_HEADERS.DRAFTS)
  }

  // Use active index
  if (accountIndex > -1) {
    account.value = response.data.accounts[accountIndex]
  } else {
    account.value = response.data.accounts[0]
  }

  // Tracking
  const origin = route.query.preview ? 'Preview' : 'Shared Link'
  $trackMixpanel({ action: 'Viewed Read-Only Link', properties: { Brand: brand.value, Idenifier: response.data.identifier, 'Number of accounts': accounts.value.length, 'Hide Drafts': hideDrafts.value, 'Has Note': note?.value?.length > 0, 'Has Expiry': !!expiry.value, Page: activeContentHeader.value, origin } })
})

function changeAccount (value: Account) {
  account.value = value
  activePostType.value = MEDIA_TYPES.ALL
  navigateTo({ path: `/b/${route.params.id}/${account.value?.id}/${activeContentHeader.value.toLowerCase()}` })
}

</script>
