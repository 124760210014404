<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { platforms, platformText, platformIconNames } from '~/constants/platforms.constants'
const emit = defineEmits<{(e: 'clickAccount', value: Account): void }>()

const props = defineProps<{
  activeAccount: Account,
  accounts: Account[] | null
}>()

const target = ref(null)
const open = ref(false)

onClickOutside(target, () => {
  open.value = false
})

const activePlatform = computed(() => {
  if (props.activeAccount.platform === platforms.NUDE) {
    return props.activeAccount.username
  }
  return platformText[props.activeAccount.platform]
})

function openDropdown (value: boolean) {
  open.value = value
}

function selectPlatform (value: Account) {
  emit('clickAccount', value)
  open.value = false
}

function onImgError (e: Event) {
  if (e.currentTarget instanceof HTMLImageElement) {
    e.currentTarget.style.display = 'none'
  }
}
</script>

<template>
  <div class="relative">
    <button
      :class="{ 'opacity-100': activeAccount }"
      type="button"
      data-dropdown-toggle="dropdown"
      class="border-plann-grey-light z-50 mr-3 flex h-[50px] items-center justify-center gap-2 rounded-xl border bg-plann-gray-input px-3 text-center text-sm opacity-0 transition duration-300 md:size-max md:min-w-[100px] md:bg-white md:px-6 md:py-3"
      @click="() => openDropdown(true)"
    >
      <div class="relative">
        <Icon v-if="activeAccount?.platform" :name="platformIconNames[activeAccount?.platform]" size="1.3em" />
        <img v-if="activeAccount.avatar" :src="activeAccount.avatar" class="absolute -bottom-1 -right-1 size-3 rounded-full" @error="onImgError">
      </div>
      <div v-if="activeAccount?.platform" class="hidden font-semibold md:flex">
        {{ activePlatform }}
      </div>

      <!-- dropdown icon -->
      <svg
        class="ml-2 size-4"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      ><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" /></svg>
    </button>

    <!-- Dropdown menu -->
    <div v-show="open" class="fixed left-0 top-0 z-20 flex size-full items-end transition duration-150 md:absolute md:left-auto md:right-2 md:top-full md:mt-3 md:h-auto md:w-max">
      <div ref="target" class="m-2 w-full rounded-3xl border border-plann-gray-light bg-white p-5 md:m-0">
        <div class="mb-2 flex items-center gap-2">
          Choose Social Platform
          <button class="ml-auto" @click="open = false">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.9998 6.99805L6.99976 18.998" stroke="#8E9AB8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.99976 6.99805L18.9998 18.998" stroke="#8E9AB8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
        <ul class="flex flex-col gap-2">
          <li v-for="(account, i) in accounts" :key="'account'+i">
            <button :class="{'bg-plann-blue-light': account.id === activeAccount?.id, 'font-semibold': account.id === activeAccount?.id}" class="flex size-full items-center rounded-lg border border-plann-gray-light p-3 px-4" @click="() => selectPlatform(account)">
              <div class="relative mr-2">
                <Icon :name="platformIconNames[account?.platform]" />
                <img v-if="account.avatar" :src="account.avatar" class="absolute -bottom-1 -right-1 size-3 rounded-full bg-plann-blue-400" @error="onImgError">
              </div>
              {{ account.platform === 'Nude' ? activeAccount.username : platformText[account.platform] }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="open" class="fixed inset-0 z-10 bg-black opacity-40 md:opacity-0" />
  </div>
</template>
