<script setup lang="ts">
import { onMounted } from 'vue'
import { Modal } from 'flowbite'
import type { ModalOptions, ModalInterface } from 'flowbite'
import { usePreviewItemModal } from '~/composables/states'
import { platformText, platforms } from '~/constants/platforms.constants'
const { $dayjs } = useNuxtApp()

const previewItemModal = usePreviewItemModal()
const account = useAccount()

const modalEl = ref(null)
const modal = ref <null | ModalInterface>(null)

const options: ModalOptions = {
  backdrop: 'dynamic',
  backdropClasses: 'bg-gray-500 bg-opacity-50 fixed inset-0 z-40',
  closable: true,
  onHide () {
    previewItemModal.value = null
  }
}

watch(previewItemModal, () => {
  previewItemModal.value?.active && modal.value!.show()
})

const date = computed(() => {
  return $dayjs.utc(previewItemModal.value?.mediaContainer.scheduled_for).tz($dayjs.tz.guess())
})

const media = computed(() => {
  return previewItemModal.value?.mediaContainer.post_media || previewItemModal.value?.mediaContainer.story_media || previewItemModal.value?.mediaContainer.reel_media || []
})

const mediaContainer = computed(() => {
  return previewItemModal.value?.mediaContainer
})

const platform = computed(() => {
  return account.value ? account.value.platform : platforms.IG_BUSINESS
})

// initialize components based on data attribute selectors
onMounted(() => {
  modal.value = new Modal(modalEl.value, options)
})

function hide () {
  modal.value && modal.value.hide()
}
</script>

<template>
  <div id="mediaModal" ref="modalEl" tabindex="-1" class="fixed inset-x-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
    <div class="relative max-h-full w-full max-w-screen-md 2xl:max-w-screen-lg min-[1800px]:max-w-screen-xl">
      <div class="relative rounded-3xl bg-white shadow">
        <button type="button" class="absolute right-0 top-0 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 md:right-2.5 md:top-3 " @click="hide">
          <svg aria-hidden="true" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="grid gap-1 md:grid-cols-2 md:gap-0">
          <div class="p-4 md:py-8 md:pl-8 md:pr-4">
            <div class="overflow-hidden rounded-xl border border-plann-gray-light bg-plann-gray-input">
              <ContentCarousel v-if="media.length > 1" :medias="media" :page-start="previewItemModal?.carouselPage" content-media-class="self-center" />
              <ContentText v-else-if="media.length === 0 && mediaContainer?.caption" />
              <ContentMedia v-else :media="media[0]" :strategy="mediaContainer?.from_strategy" strategy-class="aspect-square" />
            </div>
            <div class="mt-3 flex items-center gap-2 text-sm">
              <ContentPlatform :platform="platform" :media-type="mediaContainer?.type || 'post'" class="w-[40px] min-w-[40px]" />
              {{ platformText[platform] }}
            </div>
          </div>
          <div class="flex flex-col gap-2 p-4 md:py-8 md:pl-4 md:pr-8">
            <div class="flex flex-col gap-1">
              <span class="text-lg font-semibold capitalize text-plann-blue-900">
                {{ mediaContainer?.type }} Preview
              </span>
              <div v-if="mediaContainer?.scheduled_for" class="flex gap-1">
                <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.506 5.665H1.5M13.595 9.834a3.752 3.752 0 1 1 0 7.503 3.752 3.752 0 0 1 0-7.503" stroke="#81C4EE" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" /><path d="M11.232 16.503H4a2.501 2.501 0 0 1-2.501-2.5V3.997a2.501 2.501 0 0 1 2.501-2.501h10.004a2.501 2.501 0 0 1 2.501 2.5v7.231" stroke="#81C4EE" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" /><path d="M13.5 12.32v1.466l1.152.703M10.456 8.541a.04.04 0 1 0 0 .082.04.04 0 0 0 0-.082M7.541 8.541a.04.04 0 0 0-.04.041.04.04 0 1 0 .04-.04M4.628 8.541a.04.04 0 0 0-.041.042.04.04 0 1 0 .04-.04M7.541 11.043a.04.04 0 0 0-.04.04.04.04 0 1 0 .04-.04M4.628 11.043a.04.04 0 0 0-.041.041.04.04 0 1 0 .04-.04M7.541 13.544a.04.04 0 0 0-.04.041c0 .017.01.032.025.038a.04.04 0 1 0 .015-.079M4.628 13.544a.04.04 0 0 0-.041.041c0 .017.01.032.025.038a.04.04 0 1 0 .015-.079" stroke="#81C4EE" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" /></svg>
                <span class="flex-1 text-sm text-plann-gray-darkest">
                  Schedule for: {{ date.format('ddd, Do MMM YYYY') }} at {{ date.format('hh:mm A') }}
                </span>
              </div>
            </div>
            <div class="text-sm text-plann-gray-darkest">
              Caption:
            </div>
            <div class="mb-4 whitespace-pre-line text-sm text-plann-blue-900 break-words">
              <template v-if="mediaContainer?.caption">
                {{ mediaContainer?.caption }}
              </template>
              <template v-else>
                No caption...
              </template>
            </div>
            <template v-if="mediaContainer?.comment">
              <div class="text-sm text-plann-gray-darkest">
                First Comment:
              </div>
              <div class="whitespace-pre-line text-sm text-plann-blue-900">
                {{ mediaContainer?.comment }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#mediaModal .embla__slide {
  align-self: center;
}
</style>
