<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
const { $dayjs, $trackMixpanel } = useNuxtApp()

defineProps<{
  note: string,
  user: null | User,
  date?: null | string,
}>()

const open = ref(false)

const target = ref(null)
onClickOutside(target, () => {
  open.value = false
})

onMounted(() => {
  if (!window.localStorage.getItem('welcome-modal')) {
    window.addEventListener('plann-show-notes', openModal)
  } else {
    open.value = true
  }
})

watch(open, (val: boolean) => {
  if (val) {
    $trackMixpanel({ action: 'Viewed Note' })
  }
})

function openModal () {
  open.value = true
  window.removeEventListener('plann-show-notes', () => openModal)
}

</script>

<template>
  <div>
    <button type="button" aria-label="Notes Button" class="ml-4 h-[35px] w-[35px] rounded-xl bg-plann-gray-input md:h-[44px] md:w-[44px]" @click="open = true">
      <Icon name="NoteIcon" size="20" />
    </button>
    <div v-if="open" ref="target" role="tooltip" class="absolute left-[10vw] top-10 z-20 inline-block w-[80vw] rounded-xl border border-plann-gray-light bg-white p-6 shadow-tooltip duration-300 md:left-10 md:top-16 md:w-[400px]">
      <div class="mb-2 flex">
        <div class="text-lg font-semibold capitalize">
          A note from {{ user?.first_name }}
        </div>
        <button class="ml-auto" aria-label="Close Notes Button" @click="open = false">
          <svg aria-hidden="true" class="ml-auto h-5 w-5 text-plann-gray-darkest" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>
        </button>
      </div>
      <div class="rounded-lg border border-plann-gray-light bg-plann-gray-input p-4">
        <div class="mb-2 whitespace-pre-line break-words">
          {{ note }}
        </div>
        <div class="flex flex-col items-start sm:flex-row sm:items-center">
          <div class="flex w-full capitalize">
            <div class="mr-2 flex h-[32px] w-[32px] min-w-[32px] items-center justify-center rounded-full bg-plann-green-500 text-white">
              {{ user?.first_name.slice(0, 1) }}
            </div>
            <div class="flex w-full flex-col items-start justify-between sm:flex-row sm:items-center">
              <span>
                {{ user?.first_name }}
              </span>
              <div v-if="date" class="text-sm text-plann-gray-darkest sm:ml-auto">
                {{ $dayjs.utc(date).local().format('lll') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="open" class="fixed inset-0 z-10 bg-black opacity-40 md:opacity-0" />
  </div>
</template>
