<script setup lang="ts">
const { $dayjs, $trackMixpanel } = useNuxtApp()

defineEmits<{(e: 'clickAccount', value: Account): void }>()
defineProps<{
  user: User | null,
  accounts: Account[] | null,
  activeAccount: Account | null,
  brand: Brand | null
  note: string | null
  expiry: string | undefined
  date: string | undefined
}>()

function onJoinedClicked () {
  $trackMixpanel({ action: 'Clicked Join Plann' })
}

</script>
<template>
  <nav class="sticky left-0 top-0 z-30 h-[80px] w-full border-b border-plann-gray-light/50 bg-white md:h-[60px]">
    <div class="mx-auto flex h-fit max-w-[1920px] items-center md:justify-between">
      <div class="flex basis-0 items-center md:grow">
        <div class="relative flex items-center justify-center">
          <PlannLogo class="h-[80px] w-[80px] md:h-[60px] md:w-[60px]" />
          <NavbarNotes v-if="note" :user="user" :note="note" :date="date" class="" />
        </div>
        <span v-if="expiry" class="ml-4 hidden text-sm text-plann-gray-darkest lg:flex">Your page access expires {{ $dayjs.utc(expiry).format('MMMM Do, YYYY') }}</span>
      </div>

      <div class="ml-4 flex w-auto flex-col justify-between md:flex-row md:items-center">
        <div class="">
          Content Plan
        </div>
        <div class="mx-2 hidden md:flex">
          ·
        </div>
        <div :class="{ 'opacity-100': brand }" class="mr-4 whitespace-nowrap opacity-0 transition duration-700">
          {{ brand?.name }}
        </div>
        <div class="flex">
          <div class="hidden rounded-xl border border-gray-200 bg-plann-gray-input p-3 py-1 text-xs text-plann-gray-darkest sm:block">
            Read Only
          </div>
        </div>
      </div>

      <div class="ml-auto flex basis-0 justify-end md:grow">
        <a href="https://plannthat.com/register" target="_blank" @click="onJoinedClicked">
          <button type="button" class="mr-3 hidden w-max rounded-xl bg-plann-blue px-6 py-3 text-center text-sm font-semibold text-white hover:bg-plann-blue-600 focus:outline-none focus:ring-4 focus:ring-plann-blue-300 lg:flex">
            JOIN PLANN FOR FREE
          </button>
        </a>
        <NavbarPlatformDropdown v-if="activeAccount" :accounts="accounts" :active-account="activeAccount" @click-account="(account) => $emit('clickAccount', account)" />
      </div>
    </div>
  </nav>
</template>
